"use client"

import { ButtonAnchor, type ButtonAnchorProps } from "@/components/ui/button"
import { IconGoogle } from "@/components/IconGoogle"
import { useSession } from "./SessionContext"
import { useCallback } from "react"
import { useRouter } from "next/navigation"

export type LoginWithGoogleButtonProps = Omit<ButtonAnchorProps, "href" | "target"> & {
  target?: "_blank" | "_popup"
  redirectTo?: string
  onSuccess?: () => Promise<void> | void
}

export const LoginWithGoogleButton = ({
  onClick,
  target = "_blank",
  redirectTo,
  onSuccess,
  ...props
}: LoginWithGoogleButtonProps) => {
  const { startLogin, getLoginUrl } = useSession()
  const url = getLoginUrl({ provider: "google", redirectTo, target })
  const router = useRouter()

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (target === "_popup") {
        e.preventDefault()
        window.open(url, "googleLogin", "width=900,height=600")
      }

      startLogin(
        "google",
        onSuccess ??
          function () {
            router.push(redirectTo ?? "/")
          }
      )
      onClick?.(e)
    },
    [onClick, startLogin, target, url, onSuccess, router, redirectTo]
  )

  return (
    <ButtonAnchor
      variant="outline"
      href={url}
      onClick={handleClick}
      target={target === "_blank" ? target : undefined}
      {...props}
    >
      <IconGoogle height={24} /> Continue with Google
    </ButtonAnchor>
  )
}
